import {
  getProperty as _getProperty,
  setProperty as _setProperty,
} from 'dot-prop'
import type { IManagerFormValue } from '@manager/types'

export const useManagerFormValue = <
  T extends IManagerFormValue = IManagerFormValue,
>() => {
  const fieldGroupId = getCurrentFieldGroupId()
  const store = useManagerStore()
  const formValue = computed({
    get: () => {
      return store.formValueMap[fieldGroupId] as T
    },
    set: (value) => {
      store.formValueMap[fieldGroupId] = value
    },
  })
  const getProperty = (path: string): unknown => {
    if (!path) return
    return _getProperty(formValue.value, path)
  }
  const setProperty = (path: string, value: unknown): unknown => {
    if (!path) return
    return _setProperty(formValue.value, path, value)
  }

  return {
    formValue,
    getProperty,
    setProperty,
  }
}
