export const isDate = (d: unknown): d is Date =>
  Object.prototype.toString.call(d) === '[object Date]'

export const isValidDate = (dateString: string) => {
  // Check if the string matches a common date format using a regular expression
  const datePattern = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d{3})?Z?)?$/

  if (!datePattern.test(dateString)) {
    return false // Immediately return false if the string doesn't match the expected date format
  }

  // Parse the date
  const date = new Date(dateString)

  // Check if the date is valid
  return !isNaN(date.getTime())
}

export const isEmpty = (o: object): boolean => Object.keys(o).length === 0

export const isObject = (o: unknown): o is object =>
  o != null && typeof o === 'object'

export const hasOwnProperty = <T extends object, K extends PropertyKey>(
  o: T,
  v: K,
): o is T & Record<K, unknown> => Object.prototype.hasOwnProperty.call(o, v)

export const isEmptyObject = (o: unknown): boolean => isObject(o) && isEmpty(o)

export const isObjectWithEmptyValues = (o: unknown): boolean =>
  isObject(o) && Object.values(o).every((v) => v === null)

export const makeObjectWithoutPrototype = (): Record<PropertyKey, unknown> =>
  Object.create(null)

export const hasEmptyData = (o: unknown) =>
  isObject(o) &&
  hasOwnProperty(o, 'data') &&
  Array.isArray(o.data) &&
  o.data.length === 0

export const parseValue = (v: unknown): unknown => {
  // If the value is empty, always return `null`
  if (v === '' || v === undefined || v === null) return null
  // The format returned by the API can be `"True"` or `"False"`
  // but the format expected is `true` or `false`
  if (v === 'True') return true
  if (v === 'False') return false
  // The format returned by the API is `YYYY-MM-DDTHH:MM:SSZ`
  // but the format expected is `YYYY-MM-DDTHH:MM:SS.SSSZ`
  if (isValidDate(v as string)) return new Date(v as string)
  return v
}
